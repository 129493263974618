import { template as template_028d6581c86047c2aad237a9aa145acf } from "@ember/template-compiler";
const FKControlMenuContainer = template_028d6581c86047c2aad237a9aa145acf(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
