import { template as template_2336d9571c4445ea9b65ce590d2e5f03 } from "@ember/template-compiler";
const FKLabel = template_2336d9571c4445ea9b65ce590d2e5f03(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
