import { template as template_4a8c5aeb235044d487251e373240b57f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_4a8c5aeb235044d487251e373240b57f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
