import { template as template_27f24d55b23547c3961f1f2931ebc480 } from "@ember/template-compiler";
const SidebarSectionMessage = template_27f24d55b23547c3961f1f2931ebc480(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
